import React from 'react'
import * as myStyles from './styles.module.scss'
import { HeroContentProps } from './types'
import ClickableArea from '../../basics/clickable-area/component'
import Icon from '../../basics/icon'
import { API_TEXT } from './constants'
import Wrapper from '../../basics/wrapper'

const HeroContent = ({
    alignment = 'CENTER',
    height = 'full-height',
    pretitle,
    title,
    info,
    icon,
    color = 'orange',
    description,
    primaryCtaLink,
    primaryCtaLabel,
    secondaryCtaLink,
    secondaryCtaLabel,
}: HeroContentProps) => {
    const styles: any = myStyles
    return (
        <div
            className={styles.c_hero_content}
            data-align={alignment}
            data-color={color}
            data-height={height}>
            <Wrapper className={styles.wrapper}>
                <div className={styles.layout}>
                    <div className={styles.container}>
                        {pretitle && <h2 className={styles.pretitle}>{pretitle}</h2>}
                        {title && <h1 className={styles.title}>{title}</h1>}
                        {description && <div className={styles.description}>{description}</div>}
                        <div className={styles.ctas_container}>
                            {primaryCtaLink && (
                                <ClickableArea
                                    variant='primary'
                                    color={color}
                                    href={primaryCtaLink}>
                                    {primaryCtaLabel}
                                </ClickableArea>
                            )}
                            {secondaryCtaLink && (
                                <ClickableArea
                                    variant='secondary'
                                    color={color}
                                    href={secondaryCtaLink}>
                                    {secondaryCtaLabel}
                                </ClickableArea>
                            )}
                        </div>
                    </div>
                    {info && (
                        <div className={styles.info}>
                            {info} <Icon className={styles.icon} icon={icon} /> {API_TEXT}
                        </div>
                    )}
                </div>
            </Wrapper>
        </div>
    )
}

export default HeroContent
