import React from 'react'
import * as myStyles from './styles.module.scss'
import { PreFooterProps } from './types'
import OptionCard from '../../components/option-card'
import Wrapper from '../../basics/wrapper'
import Marquee from '../../components/marquee'

const PreFooter: React.FC<PreFooterProps> = ({
    title,
    reviews,
    cardsData,
    background = 'dark',
    withoutPatternBg = false,
    isSmall = false,
}) => {
    const styles: any = myStyles

    return (
        <div
            className={styles.m_pre_footer}
            data-background={background}
            data-small={isSmall}
            data-bg-pattern={!withoutPatternBg}>
            {reviews && reviews.length > 0 && (
                <div className={styles.marquee}>
                    <Marquee texts={reviews} />
                </div>
            )}
            <Wrapper>
                <h1 className={styles.title}>{title}</h1>
                <div className={styles.card_content}>
                    {cardsData.map((cardProps, index) => (
                        <OptionCard key={index} {...cardProps} isSmall={isSmall} />
                    ))}
                </div>
            </Wrapper>
        </div>
    )
}

export default PreFooter
