import React from 'react'
import {
    DemoIcon,
    ArityLogo,
    ArticleLogo,
    NuvoLogo,
    AvanzaLogo,
    DownloadBigIcon,
    MarineLogo,
    PlaytikaLogo,
    VortexaLogo,
    VisecaLogo,
    AdidasLogo,
    BlumeLogo,
    PoeLogo,
    DaimlerLogo,
    CameoLogo,
    CityFibreLogo,
    EPLogo,
    EuroInformationLogo,
    FlippLogo,
    GeneraliLogo,
    GeotabLogo,
    HoneyBookLogo,
    KandjiLogo,
    PlexusLogo,
    OtpBankLogo,
    OCTannerLogo,
    TelnetLogo,
    SasLogo,
    Babylon,
    FlowTradersLogo,
} from '../../lib/components/basics/icons'

import arityImg from '../../../resources/images/hanzo/customers/arity-thumb.webp'
import articleImg from '../../../resources/images/hanzo/customers/article-thumb.webp'
import avanzaImg from '../../../resources/images/hanzo/customers/avanza-thumb.webp'
import babylonImg from '../../../resources/images/hanzo/customers/babylon-thumb.webp'
import marineImg from '../../../resources/images/hanzo/customers/marineTraffic-thumb.webp'
import nuvoImg from '../../../resources/images/hanzo/customers/nuvo-thumb.webp'
import playtikaImg from '../../../resources/images/hanzo/customers/playtika-thumb.webp'
import visecaImg from '../../../resources/images/hanzo/customers/viseca-thumb.webp'
import vortexaImg from '../../../resources/images/hanzo/customers/vortexa-thumb.webp'

// HERO PROPERTIES (Used in HeroModule component)
export const heroProps = {
    alignment: 'LEFT' as const,
    color: 'blue' as const,
    title: (
        <h2>
            How <b>Arity</b> accelerated their Kafka adoption and saved costs by 40%
        </h2>
    ),
    primaryCtaLink: '/customers/arity-apache-kafka-costs-amazon-msk',
    primaryCtaLabel: 'Read case study',
}

// FEATURED SUCCESS STORIES (Used in PreFooter component)
export const featuredStoriesTitle = 'Featured Success stories'

export const featuredStoriesCardsData = [
    {
        title: 'How Arity accelerated their Kafka adoption and saved costs by 40%',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/arity-apache-kafka-costs-amazon-msk',
        logo: <ArityLogo key='arity-2' />,
        icon: <img src={arityImg} alt='article-thumb' />,
    },
    {
        title: 'How Article is winning market share using Apache Kafka',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/article-wins-marketshare-using-apache-kafka',
        logo: <ArticleLogo key='aws' />,
        icon: <img src={articleImg} alt='article-thumb' />,
    },
    {
        title: 'Accessing and processing real-time heart rate data',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/how-nuvo-processes-real-time-data-with-apache-kafka',
        logo: <NuvoLogo key='nuvo' />,
        icon: <img src={nuvoImg} alt='article-thumb' />,
    },
    {
        title: 'How developers democratize data across all their Kafka Streams',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/avanza',
        logo: <AvanzaLogo key='avanza' />,
        icon: <img src={avanzaImg} alt='article-thumb' />,
    },
    {
        title: 'Accelerating data engineering to improve the health of millions',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/videos/babylon-health-dataops-apache-kafka/',
        logo: <Babylon key='baylon' />,
        icon: <img src={babylonImg} alt='article-thumb' />,
    },
    {
        title: 'How MarineTraffic tracks shipping fleet data with Apache Kafka',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/marine-traffic',
        logo: <MarineLogo key='marine' />,
        icon: <img src={marineImg} alt='article-thumb' />,
    },
    {
        title: 'How Playtika takes DataOps to production with Lenses',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/playtika',
        logo: <PlaytikaLogo key='playtika' />,
        icon: <img src={playtikaImg} alt='article-thumb' />,
    },
    {
        title: 'How Lenses makes Kafka smooth sailing at Vortexa',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/vortexa',
        logo: <VortexaLogo key='vortexa' />,
        icon: <img src={vortexaImg} alt='article-thumb' />,
    },
    {
        title: 'A Streaming Data Platform for real-time customer interactions',
        type: 'tertiary' as const,
        buttonText: 'Read the full story',
        href: '/customers/viseca/',
        logo: <VisecaLogo key='viseca' />,
        icon: <img src={visecaImg} alt='article-thumb' />,
    },
]

// GRAPHICS AND LOGOS
export const graphicsAndLogos = {
    title: 'One operating layer all your Kafkas',
    logos: [
        <PoeLogo key='poe' />,
        <AdidasLogo key='adidas' />,
        <ArityLogo key='arity' />,
        <BlumeLogo key='blume' />,
        <CameoLogo key='cameo' />,
        <CityFibreLogo key='city-fibre' />,
        <DaimlerLogo key='daimler' />,
        <EPLogo key='ep' />,
        <EuroInformationLogo key='euro-information' />,
        <FlippLogo key='flipp' />,
        <GeneraliLogo key='generali' />,
        <GeotabLogo key='geotab' />,
        <HoneyBookLogo key='honeybook' />,
        <KandjiLogo key='kandji' />,
        <MarineLogo key='marine' />,
        <OCTannerLogo key='oc-tanner' />,
        <OtpBankLogo key='otp-bank' />,
        <PlaytikaLogo key='playtika' />,
        <PlexusLogo key='plexus' />,
        <TelnetLogo key='telnet' />,
        <SasLogo key='sas' />,
        <VortexaLogo key='vortexa' />,
        <VisecaLogo key='viseca' />,
        <FlowTradersLogo key='flow-traders' />,
    ],
}

export const preFooterTitle = 'Try Lenses for yourself'

export const cardsDataPrefooter = [
    {
        title: (
            <>
                20 minute
                <br />
                demo
            </>
        ),
        type: 'primary',
        description: [
            'Guided tour of Lenses',
            'Trial key to install on your clusters',
            'Great for production and large-scale Kafka deployments',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demoIcon' />,
    },
    {
        title: 'Free Lenses',
        leadingText: 'Run the docker command to get Lenses Community Edition:',
        type: 'secondary',
        description: [
            'Apache Kafka Docker + Lenses 6.0',
            'Pre-loaded synthetic data',
            'Up to 2 users + environments',
        ],
        buttonText: 'Download',
        href: '/apache-kafka-docker',
        icon: <DownloadBigIcon key='downloadBigIcon' />,
    },
]
